import { enUsLocaleName, ukUaLocaleName } from "@/utils/constants";
import Joi from "joi";

const firstNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Ім'я повинно бути рядком.",
        "string.min": "Ім'я не може бути коротше аніж 2 символи.",
        "string.max": "Ім'я не може бути довше аніж 100 символів.",
        "string.empty": "Ім'я не може бути порожнім.",
        "any.required": "Ім'я є обов'язковим.",
        "string.pattern.base": "Ім'я може містити лише літери.",
    },
    [enUsLocaleName]: {
        "string.base": "First name must be a string.",
        "string.min": "First name must be at least 2 characters long.",
        "string.max": "First name cannot be longer than 100 characters.",
        "string.empty": "First name cannot be empty.",
        "any.required": "First name is required.",
        "string.pattern.base": "First name may only contain letters.",
    },
};

const lastNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Прізвище повинно бути рядком.",
        "string.min": "Прізвище не може бути коротше аніж 3 символи.",
        "string.max": "Прізвище не може бути довше аніж 100 символів.",
        "string.empty": "Прізвище не може бути порожнім.",
        "any.required": "Прізвище є обов'язковим.",
        "string.pattern.base": "Прізвище може містити лише літери.",
    },
    [enUsLocaleName]: {
        "string.base": "Last name must be a string.",
        "string.min": "Last name must be at least 3 characters long.",
        "string.max": "Last name cannot be longer than 100 characters.",
        "string.empty": "Last name cannot be empty.",
        "any.required": "Last name is required.",
        "string.pattern.base": "Last name may only contain letters.",
    },
};

const middleNameErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "По батькові повинно бути рядком.",
        "string.min": "По батькові не може бути коротше аніж 2 символи.",
        "string.max": "По батькові не може бути довше аніж 100 символів.",
        "string.pattern.base": "По батькові може містити лише літери.",
        "string.empty": "По батькові не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Middle name must be a string.",
        "string.min": "Middle name must be at least 2 characters long.",
        "string.max": "Middle name cannot be longer than 100 characters.",
        "string.pattern.base": "Middle name may only contain letters.",
        "string.empty": "Middle name cannot be empty.",
    },
};

const phoneNumberErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Номер телефону повинен бути рядком.",
        "string.pattern.base": "Введіть дійсний номер телефону.",
        "string.empty": "Поле номеру телефону не може бути порожнім.",
        "any.required": "Номер телефону є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Phone number must be a string.",
        "string.pattern.base": "Please enter a valid phone number.",
        "string.empty": "Phone number field cannot be empty.",
        "any.required": "Phone number is required.",
    },
};

const costErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Вартість повинна бути числом.",
        "number.min": "Вартість повинна бути більша або рівна 1.",
        "any.required": "Вартість є обов'язковою.",
    },
    [enUsLocaleName]: {
        "number.base": "Cost must be a number.",
        "number.min": "Cost must be greater than or equal to 1.",
        "any.required": "Cost is required.",
    },
};

const cityErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Місто повинно бути рядком.",
        "string.min": "Місто повинно містити мінімум 3 символи.",
        "string.max": "Місто не може бути довше аніж 100 символів.",
        "any.required": "Місто є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "City must be a string.",
        "string.min": "City must contain at least 3 characters.",
        "string.max": "City cannot be longer than 100 characters.",
        "any.required": "City is required.",
    },
};

const counterPartyTypeErrMsgs = {
    [ukUaLocaleName]: {
        "any.required": "Тип контрагента є обов'язковим.",
    },
    [enUsLocaleName]: {
        "any.required": "Counterparty type is required.",
    },
};

const descriptionOfTheCargoErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Опис вантажу повинен бути рядком.",
        "string.min": "Опис вантажу не може бути порожнім.",
        "string.max": "Опис вантажу не може бути довше 100 символів.",
        "any.required": "Опис вантажу є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Cargo description must be a string.",
        "string.min": "Cargo description cannot be empty.",
        "string.max": "Cargo description cannot be longer than 100 characters.",
        "any.required": "Cargo description is required.",
    },
};

const paymentMethodErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Метод оплати повинен бути рядком.",
        "any.required": "Метод оплати є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Payment method must be a string.",
        "any.required": "Payment method is required.",
    },
};

const payerOfDeliveryErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Платник доставки повинен бути рядком.",
        "any.required": "Платник доставки є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Payer of delivery must be a string.",
        "any.required": "Payer of delivery is required.",
    },
};

const cargoTypeErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Тип вантажу повинен бути рядком.",
        "any.required": "Тип вантажу є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Cargo type must be a string.",
        "any.required": "Cargo type is required.",
    },
};

const serviceTypeErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Тип послуги повинен бути рядком.",
        "any.required": "Тип послуги є обов'язковим.",
    },
    [enUsLocaleName]: {
        "string.base": "Service type must be a string.",
        "any.required": "Service type is required.",
    },
};

const addressErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Адреса повинна бути рядком.",
        "any.required": "Адреса є обов'язковою.",
    },
    [enUsLocaleName]: {
        "string.base": "Address must be a string.",
        "any.required": "Address is required.",
    },
};

const totalWeightErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Загальна вага повинна бути числом.",
        "number.min": "Загальна вага повинна бути більшою за 0.1 кг.",
    },
    [enUsLocaleName]: {
        "number.base": "Total weight must be a number.",
        "number.min": "Total weight must be greater than 0.1 kg.",
    },
};

const numberOfSeatsErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Кількість місць повинна бути числом.",
        "number.min": "Кількість місць повинна бути більшою за 0.",
    },
    [enUsLocaleName]: {
        "number.base": "Number of seats must be a number.",
        "number.min": "Number of seats must be greater than 0.",
    },
};

const totalVolumeOfShipmentsErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Загальний об'єм вантажу повинен бути числом.",
        "number.min": "Загальний об'єм вантажу повинен бути більший за 0.",
    },
    [enUsLocaleName]: {
        "number.base": "Total volume of shipments must be a number.",
        "number.min": "Total volume of shipments must be greater than 0.",
    },
};

const useGeneralParamsErrMsgs = {
    [ukUaLocaleName]: {
        "boolean.base": "Параметри загальних умов повинні бути булевими.",
    },
    [enUsLocaleName]: {
        "boolean.base": "General parameters must be boolean.",
    },
};

const typeOfReceivingMoneyErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Тип отримання грошей повинен бути рядком.",
        "string.empty": "Тип отримання грошей не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Type of receiving money must be a string.",
        "string.empty": "Type of receiving money cannot be empty.",
    },
};

const payerOfCashOnDeliveryErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Платник при отриманні повинен бути рядком.",
        "string.empty": "Платник при отриманні не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Payer on delivery must be a string.",
        "string.empty": "Payer on delivery cannot be empty.",
    },
};

const paymentControlEnabledValueErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Контроль платежу повинен бути рядком.",
        "string.empty": "Контроль платежу не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Payment control must be a string.",
        "string.empty": "Payment control cannot be empty.",
    },
};

const senderTypeDepartmentErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Тип відправника повинен бути рядком.",
        "string.empty": "Тип відправника не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Sender type department must be a string.",
        "string.empty": "Sender type department cannot be empty.",
    },
};

const senderSettlementErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Населений пункт відправника повинен бути рядком.",
        "string.empty": "Населений пункт відправника не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Sender settlement must be a string.",
        "string.empty": "Sender settlement cannot be empty.",
    },
};

const senderDepartmentsErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Відділи відправника повинні бути рядком.",
        "string.empty": "Відділи відправника не можуть бути порожніми.",
    },
    [enUsLocaleName]: {
        "string.base": "Sender departments must be a string.",
        "string.empty": "Sender departments cannot be empty.",
    },
};

const postPaidErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Поле оплати після доставки повинно бути рядком.",
        "string.empty": "Поле оплати після доставки не може бути порожнім.",
        "string.pattern.base": "Поле оплати після доставки має містити лише додатні числа, більші за 0.",
    },
    [enUsLocaleName]: {
        "string.base": "Post-paid field must be a string.",
        "string.empty": "Post-paid field cannot be empty.",
        "string.pattern.base": "Post-paid field must contain only positive numbers greater than 0.",
    },
};

const additionalInformationErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Додаткова інформація повинна бути рядком.",
        "string.empty": "Додаткову інформацію не можна залишати порожньою.",
    },
    [enUsLocaleName]: {
        "string.base": "Additional information must be a string.",
        "string.empty": "Additional information cannot be empty.",
    },
};

const packageNumberErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Номер пакунка повинен бути рядком.",
        "string.empty": "Номер пакунка не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Package number must be a string.",
        "string.empty": "Package number cannot be empty.",
    },
};

const recipientTypeDepartmentErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Тип відділу отримувача повинен бути рядком.",
        "string.empty": "Тип відділу отримувача не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Recipient type department must be a string.",
        "string.empty": "Recipient type department cannot be empty.",
    },
};

const recipientDepartmentsErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Відділи отримувача повинні бути рядком.",
        "string.empty": "Відділи отримувача не можуть бути порожніми.",
    },
    [enUsLocaleName]: {
        "string.base": "Recipient departments must be a string.",
        "string.empty": "Recipient departments cannot be empty.",
    },
};

const recipientSettlementErrMsgs = {
    [ukUaLocaleName]: {
        "string.base": "Населений пункт отримувача повинен бути рядком.",
        "string.empty": "Населений пункт отримувача не може бути порожнім.",
    },
    [enUsLocaleName]: {
        "string.base": "Recipient settlement must be a string.",
        "string.empty": "Recipient settlement cannot be empty.",
    },
};

const weightErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Вага повинна бути числом.",
        "number.min": "Вага повинна бути більшою за 0.1 кг.",
    },
    [enUsLocaleName]: {
        "number.base": "Weight must be a number.",
        "number.min": "Weight must be greater than 0.1 kg.",
    },
};

const volumetricWidthErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Ширина повинна бути числом.",
        "number.min": "Ширина повинна бути більшою за 0.1.",
    },
    [enUsLocaleName]: {
        "number.base": "Volumetric width must be a number.",
        "number.min": "Volumetric width must be greater than 0.1.",
    },
};

const volumetricVolumeErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Об'єм повинен бути числом.",
        "number.min": "Об'єм повинен бути більшим за 0.1.",
    },
    [enUsLocaleName]: {
        "number.base": "Volumetric volume must be a number.",
        "number.min": "Volumetric volume must be greater than 0.1.",
    },
};

const volumetricHeightErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Висота повинна бути числом.",
        "number.min": "Висота повинна бути більшою за 0.1.",
    },
    [enUsLocaleName]: {
        "number.base": "Volumetric height must be a number.",
        "number.min": "Volumetric height must be greater than 0.1.",
    },
};

const volumetricLengthErrMsgs = {
    [ukUaLocaleName]: {
        "number.base": "Довжина повинна бути числом.",
        "number.min": "Довжина повинна бути більшою за 0.1.",
    },
    [enUsLocaleName]: {
        "number.base": "Volumetric length must be a number.",
        "number.min": "Volumetric length must be greater than 0.1.",
    },
};

const today = new Date();
today.setDate(today.getDate());
today.setHours(0, 0, 0, 0);

const lettersOnlyRegex = /^[a-zA-ZА-Яа-яІіЇїЄє]+$/;

export const createNewDeliverySchema = Joi.object({
    senderCity: Joi.string().min(3).max(100).required().messages(cityErrMsgs),
    senderPhone: Joi.string()
        .regex(/^(?:\+?380|0)\d{9}$/)
        .required()
        .messages(phoneNumberErrMsgs),
    recipientPhone: Joi.string()
        .regex(/^(?:\+?380|0)\d{9}$/)
        .required()
        .messages(phoneNumberErrMsgs),
    senderFirstName: Joi.string().min(2).max(100).regex(lettersOnlyRegex).required().messages(firstNameErrMsgs),
    senderLastName: Joi.string().min(3).max(100).regex(lettersOnlyRegex).required().messages(lastNameErrMsgs),
    senderMiddleName: Joi.string().min(2).max(100).regex(lettersOnlyRegex).allow("").optional().messages(middleNameErrMsgs),
    recipientFirstName: Joi.string().min(3).max(100).regex(lettersOnlyRegex).required().messages(firstNameErrMsgs),
    recipientLastName: Joi.string().min(3).max(100).regex(lettersOnlyRegex).required().messages(lastNameErrMsgs),
    recipientMiddleName: Joi.string().min(2).max(100).regex(lettersOnlyRegex).optional().allow("").messages(middleNameErrMsgs),
    recipientCity: Joi.string().min(3).max(100).required().messages(cityErrMsgs),
    senderCounterPartyType: Joi.any().not("").required().messages(counterPartyTypeErrMsgs),
    dateTime: Joi.date().min(today).optional(),
    cost: Joi.number().integer().min(1).required().messages(costErrMsgs),
    AfterpaymentOnGoodsCost: Joi.number().integer().min(1).optional(),
    recipientCounterPartyType: Joi.string().not("").required().messages(counterPartyTypeErrMsgs),
    descriptionOfTheCargo: Joi.string().min(1).max(100).required().messages(descriptionOfTheCargoErrMsgs),
    paymentMethod: Joi.string().not("").required().messages(paymentMethodErrMsgs),
    payerOfDelivery: Joi.string().not("").required().messages(payerOfDeliveryErrMsgs),
    cargoType: Joi.string().not("").required().messages(cargoTypeErrMsgs),
    serviceType: Joi.string().not("").required().messages(serviceTypeErrMsgs),
    recipientAddress: Joi.string().not("").required().messages(addressErrMsgs),
    senderAddress: Joi.string().not("").required().messages(addressErrMsgs),
    totalWeight: Joi.number().min(0.1).optional().messages(totalWeightErrMsgs),
    numberOfSeats: Joi.number().optional().messages(numberOfSeatsErrMsgs),
    totalVolumeOfShipments: Joi.number().optional().messages(totalVolumeOfShipmentsErrMsgs),
    useGeneralParams: Joi.boolean().optional().messages(useGeneralParamsErrMsgs),
    typeOfReceivingMoney: Joi.string().optional().messages(typeOfReceivingMoneyErrMsgs),
    payerOfCashOnDelivery: Joi.string().optional().messages(payerOfCashOnDeliveryErrMsgs),
    paymentControlEnabledValue: Joi.string().optional().messages(paymentControlEnabledValueErrMsgs),
    senderTypeDepartment: Joi.string().optional().messages(senderTypeDepartmentErrMsgs),
    senderSettlement: Joi.string().optional().messages(senderSettlementErrMsgs),
    senderDepartments: Joi.string().optional().messages(senderDepartmentsErrMsgs),
    postPaid: Joi.string()
        .pattern(/^[1-9]\d*$/)
        .optional()
        .messages(postPaidErrMsgs),
    additionalInformation: Joi.string().optional().messages(additionalInformationErrMsgs),
    packageNumber: Joi.string().optional().messages(packageNumberErrMsgs),
    recipientTypeDepartment: Joi.string().optional().messages(recipientTypeDepartmentErrMsgs),
    recipientDepartments: Joi.string().optional().messages(recipientDepartmentsErrMsgs),
    recipientSettlement: Joi.string().optional().messages(recipientSettlementErrMsgs),
    weight: Joi.number().min(0.1).optional().messages(weightErrMsgs),
    volumetricWidth: Joi.number().min(0.1).optional().messages(volumetricWidthErrMsgs),
    volumetricVolume: Joi.number().min(0.1).optional().messages(volumetricVolumeErrMsgs),
    volumetricHeight: Joi.number().min(0.1).optional().messages(volumetricHeightErrMsgs),
    volumetricLength: Joi.number().min(0.1).optional().messages(volumetricLengthErrMsgs),
}).options({ presence: "optional" });
