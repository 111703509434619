const mail = {
    noEmailAccount: "Email account not connected",
    connectButton: "Connect email",
    newMail: "New mail",
    emptyMessage: "There is no data",
    emptySearchResult: "No results found",
    newMessage: {
        title: "New message",
        from: "From",
        to: "To",
        subject: "Subject",
        placeholder: "Add text.....",
        sendButton: "Send",
        saveAsDraft: "Save as draft",
        errorBodyTooLong: "The message body is too long.",
        errorMissingRecipient: "Please provide at least one recipient.",
        errorInvalidRecipient: "One or more of the provided recipients are invalid.",
        errorEmptyBody: "The message body cannot be empty.",
        errorMissingFields: "Please fill in all required fields.",
    },
    filters: {
        title: "Filter",
        unseen: "Unread",
        withAttachments: "With Attachments",
        notLead: "Not Linked to Lead",
        notClient: "Not Linked to Client",
        apply: "Apply",
        clear: "Clear",
    },
    createAccount: {
        title: "Connect email account",
        name: "Sender name",
        email: "Email",
        password: "Password",
        imapHost: "Imap server",
        port: "Port",
        smtpHost: "SMTP server",
        send: "Confirm",
        notUsingImap: "Not using IMAP?",
        instructionDescr: "To continue type your account credentials",
        recomend: "Instead of IMAP, we recomend",
        reason: "to ensure a more reliable email sync",
        usingAltProvider: "using an alternative email provider",
        changeEmailProvider: "Change an email provider",
        instruction: "instruction",
    },
    actions: {
        markAsTitle: "Mark as",
        seen: "Read",
        unSeen: "Unread",
        starred: "Starred",
        notStarred: "Unstarred",
        archive: "Archiving",
    },
    back: "Back",
    reply: "Reply",
    forward: "Forward",
    markAsUnread: "Mark as Unread",
    archive: "Archive",
    unarchive: "Unarchive",
    peopleInThread: "People in this Thread",
    addToExistingLead: "Add to Existing Lead",
    createNewLead: "Create New Lead",
    addToExistingClient: "Add to existing client",
    bindToClient: "Bind to a Client or create deal",
    findExistingDeal: "Find an existing deal or lead. Or create a new deal.",
    addNewDeal: "Create New Deal",
    goToLead: "View deal",
    goToClient: "View client",
    confirm: "Confirm",
    addLeadEmailTitle: "Add new email contact for lead",
    addClientEmailTitle: "Add new email contact for client",
    selectLeadTitle: "Lead",
    selectClientTitle: "Client",
    mailSent: "Mail sent",
    viewMail: "View mail",
    draftTitle: "Draft",
    whom: "Whom:",
};

export default mail;
