import { DeliveryProviders } from "@/services/enums";

const leads = {
    title: "Leads",
    subtitle: "Information about the leads",
    table: {
        title: "List of leads",
        emptyMessage: "There is no data",
        emptySearchResult: "No results found",
        panel: {
            searchPlaceholder: "Search",
            archive: "Archive",
            create: "Create lead",
            filter: "Filter",
        },
        columns: {
            id: "ID",
            name: "Name / Company name",
            type: "Lead type",
            responsible: "Responsible",
            createdAt: "Created",
            source: "Source",
            status: "Status",
            email: "Email",
            contactPerson: "Contact person",
            phoneNumber: "Phone number",
            comment: "Comment",
            actions: "Actions",
            novaPostDeliveryAddress: "Nova post address",
            novaPostDeliveryAddressCity: "Nova post city",
            novaPostDeliveryAddressDepartment: "Nova post department",
            novaPostDeliveryAddressDepartmentType: "Nova post department type",
            ukrPostDeliveryAddress: "Ukr post address",
            ukrPostDeliveryAddressCity: "Ukr post city",
            ukrPostDeliveryAddressDepartment: "Ukr post department",
            firstName: "First name",
            lastName: "Last name",
            middleName: "Middle name",
            position: "Position",
            tasks: "Tasks",
        },
    },
    modal: {
        header: "Create a lead",
        inputs: {
            name: "Name / Company name *",
            nameError: "Name is incorrect",
            responsibleUser: "Responsible *",
            comment: "Comment",
            email: "Email *",
            emailError: "Email format must be {format}",
            phone: "Phone number *",
            phoneError: "Phone number is incorrect",
            contactPerson: "Contact person",
            contactPersonError: "Contact person name length must be longer than or equal to 3 characters",
            status: "Status",
            typeId: "Lead type *",
            source: "Source",
            [DeliveryProviders.NOVA_POST]: {
                city: "Nova post city",
                department: "Nova post department",
                departmentType: "Nova post department type",
            },
            [DeliveryProviders.UKR_POST]: {
                city: "Ukr post city",
                department: "Ukr post department",
            },
        },
        create: "Create",
    },
    filter: {
        header: "Leads filter",
        inputs: {
            type: "Lead type",
            responsibleUser: "Responsible user",
            createdTo: "Created to",
            createdFrom: "Created from",
            source: "Source",
            status: "Status",
        },
        placeholders: {
            name: "Type company name",
            email: "Type lead email",
        },
        apply: "Apply",
        clear: "Clear",
    },
    card: {
        title: "Personal lead card",
        info: {
            title: "Information about the lead",
            saveButton: "Save",
        },
        additionalContacts: {
            title: "Additional contacts",
            add: "Add",
        },
    },
    boardView: {
        noStatus: "No status",
        copyLink: "Copy link",
        convertToClient: "Convert to client",
        archive: "Archive",
        unArchive: "Unarchive",
    },
    convertModal: {
        convertToClient: "Convert this lead to client?",
        dataNotice: "All the data from the following custom fields will be cleared:",
    },
};

export default leads;
