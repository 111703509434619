import { createApp } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import Toast from "vue-toastification";
import VueTippy from "vue-tippy";
import * as Sentry from "@sentry/vue";

import "../teliqon-ui-kit/src/style.css";
import "./assets/css/tailwind.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-away.css";
import "vue-toastification/dist/index.css";

const app = createApp(App).use(store).use(i18n).use(VueQueryPlugin).use(VueTippy).use(Toast);

if (process.env.VUE_APP_SENTRY_DSN) {
    console.warn("init sentry");
    Sentry.init({
        app,
        // debug: true,
        environment: process.env.VUE_APP_NODE_ENV,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
        tracesSampleRate: 0,
        tracePropagationTargets: [process.env.VUE_APP_API_BASE_URL, process.env.VUE_APP_SIP_WEBSOCKET_HOST],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    });
}

app.use(router);

app.mount("#app");
