const clients = {
    title: "Clients",
    subtitle: "Track the essentials on the Dashboard",
    table: {
        title: "Information about the clients",
        emptyMessage: "There is no data",
        emptySearchResult: "No results found",
        panel: {
            searchPlaceholder: "Search",
            archive: "Archive",
            create: "Create client",
            filter: "Filter",
        },
        columns: {
            id: "ID",
            name: "Name / Company name",
            type: "Type",
            responsible: "Responsible",
            createdAt: "Created",
            source: "Source",
            status: "Status",
            email: "Email",
            contactPerson: "Contact person",
            phoneNumber: "Phone number",
            comment: "Comment",
            actions: "Actions",
            novaPostDeliveryAddress: "Nova post address",
            novaPostDeliveryAddressCity: "Nova post city",
            novaPostDeliveryAddressDepartment: "Nova post department",
            novaPostDeliveryAddressDepartmentType: "Nova post department type",
            ukrPostDeliveryAddress: "Ukr post address",
            ukrPostDeliveryAddressCity: "Ukr post city",
            ukrPostDeliveryAddressDepartment: "Ukr post department",
            conversionTime: "Data of the changing status lead -> client",
            tasks: "Tasks",
        },
    },
    modal: {
        header: "Create a client from a lead",
        inputs: {
            lead: "Lead",
        },
        create: "Convert to client",
        leadData: "Selected lead info",
    },
    filter: {
        header: "Clients filter",
        inputs: {
            type: "Client type",
            responsibleUser: "Responsible user",
            createdTo: "Created to",
            createdFrom: "Created from",
            source: "Source",
            status: "Status",
        },
        placeholders: {
            name: "Type company name",
            email: "Type client email",
        },
        apply: "Apply",
        clear: "Clear",
    },
    card: {
        title: "Personal client card",
        info: {
            title: "Information about the client",
            saveButton: "Save",
        },
    },
    boardView: {
        noStatus: "No status",
        copyLink: "Copy link",
        archive: "Archive",
        unArchive: "Unarchive",
    },
};

export default clients;
