const calls = {
    title: "Дзвінки",
    subtitle: "Відслідковуй найголовніше на Дешборді",
    addLead: "Лід",
    table: {
        title: "Інформація про дзвінки",
        emptyMessage: "Таблиця не містить даних",
        emptySearchResult: "Даних не знайдено за вашим запитом",
        panel: {
            searchPlaceholder: "Пошук",
            tabs: {
                all: "Всі дзвінки",
                incoming: "Вхідні",
                outgoing: "Вихідні",
                missed: "Пропущені",
            },
            filter: "Фільтр",
        },
        columns: {
            id: "ID",
            type: "Помітка",
            sourceNumber: "Номер з якого дзвонили",
            targetNumber: "Номер на який дзвонили",
            internalNumber: "Внутр. номер на який/з якого був дзвінок",
            duration: "Тривалість дзвінка",
            startedAt: "Дата-час дзвінка",
            actions: "Дії",
            buttons: {
                deal: "Угода",
                add: "Додати",
            },
        },
        callTypes: {
            incoming: "Вхідний",
            outgoing: "Вихідний",
            missed: "Пропущений",
        },
    },
    filter: {
        header: "Фільтр",
        inputs: {
            sourceNumber: "Номер з якого дзвонили",
            targetNumber: "Номер на який дзвонили",
            internalNumber: "Внутрішній номер",
            duration: "Тривалість дзвінка (сек)",
            startedFrom: "Дата-час дзвінка від",
            startedTo: "Дата-час дзвінка до",
        },
        placeholders: {
            number: "Введіть номер",
        },
        apply: "Застосувати",
        clear: "Скинути",
    },
    callWindow: {
        titleFrom: "Дзвінок від: ",
        titleTo: "Дзвінок на номер: ",
        buttons: {
            hide: "Згорнути",
            createDeal: "Створити угоду",
            createTask: "Створити завдання",
            answer: "Відповісти",
            skip: "Пропустити",
            hangup: "Завершити",
            cancel: "Відмінити",
        },
        callTimeTitle: "Час розмови: ",
        callStatuses: {
            busy: "Абонент зайнятий",
            connecting: "Встановлюється звʼязок",
            notRegistered: "У вас немає зареєстрованого номеру",
            awaiting: "Абонент очікує на лінії",
            connected: "Зв'язок встановлений",
        },
        tasksTitle: "Завдання:",
        dealsTitle: "Угоди:",
        responsibleTitle: "Відповідальний:",
        unknownUser: "Невідомий користувач",
    },
    transferToWindow: {
        header: "Переадресувати дзвінок на: ",
        title: "Користувач",
        button: "Переадресувати",
    },
};

export default calls;
