const tasks = {
    title: "Tasks",
    subtitle: "Track the essentials on the Dashboard",
    table: {
        title: "Information about the tasks",
        emptyMessage: "There is no data",
        emptySearchResult: "No results found",
        panel: {
            searchPlaceholder: "Search",
            archive: "Archive",
            create: "Create task",
            filter: "Filter",
        },
        columns: {
            id: "ID",
            name: "Name",
            responsible: "Main responsible",
            status: "Status",
            createdBy: "Created by",
            createdAt: "Created at",
            comment: "Comment",
            customer: "Lead / Client",
            endDate: "End date (own deadline)",
            deal: "Deal",
            finishedAt: "Finished",
            actions: "Actions",
            lead: "Lead",
            client: "Client",
            responsibleContact: "Responsible contact",
            noResponsibleContacts: "Selected essence does not have additional contacts",
            priority: "Priority",
            selectEssence: {
                leads: "Firstly, select lead",
                clients: "Firstly, select client",
            },
        },
    },
    modal: {
        header: "Create task",
        inputs: {
            name: "Task name *",
            nameError: "Incorrect task name",
            responsibleUser: "Main responsible *",
            comment: "Comment",
            status: "Status",
            createdBy: "Created",
            customerType: "Leads / Clients",
            leads: "Lead",
            clients: "Client",
            endDate: "End date (own deadline)",
            deal: "Deal",
            responsibleContact: "Responsible contact",
        },
        create: "Create",
        createError: "Task creation error",
    },
    filter: {
        header: "Tasks filter",
        inputs: {
            name: "Task name",
            responsibleUser: "Responsible",
            creator: "Creator",
            source: "Створено",
            customerType: "Lead/Client",
            endDate: "Date of the task end",
            endDateFrom: "Date of the task end from",
            endDateTo: "Date of the task end to",
            deal: "Deal",
            leads: "Lead",
            clients: "Client",
            status: "Status",
            createdAt: "Created at",
            createdFrom: "Created at from",
            createdTo: "Created at to",
        },
        placeholders: {
            name: "Type task name",
            responsibleUser: "Type name",
            creator: "Type name",
            deal: "Type deal name",
        },
        apply: "Apply",
        clear: "Clear",
    },
    card: {
        title: "Personal task card",
        info: {
            title: "Information about the task",
            saveButton: "Save",
        },
        modals: {
            confirmDeleteDeal: {
                title: "Only tasks assigned to a client can have a deal attached.",
                subtitle: "Are you sure you want to assign this task to a lead?",
                subtitle2: "The deal will be removed from the task",
            },
        },
    },
    selectCustomerType: {
        leads: "Leads",
        clients: "Clients",
    },
    boardView: {
        noStatus: "No status",
        copyLink: "Copy link",
        archive: "Archive",
        unArchive: "Unarchive",
        card: {
            lead: "Lead",
            client: "Client",
            deal: "Deal",
        },
    },
    logs: {
        lead: "Lead",
        client: "Client",
    },
};

export default tasks;
