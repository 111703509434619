const additionalContacts = {
    title: "Additional contacts",
    add: "Add",
    emptyMessage: "There is no data",
    emptySearchResult: "No results found",
    columns: {
        fullName: "Full name",
        email: "Email",
        phoneNumber: "Phone number",
        position: "Position",
        city: "City",
        postDepartment: "Post department",
        taskCount: "Task count",
        dealCount: "Deal count",
        actions: "Actions",
        firstName: "First name",
        lastName: "Last name",
        middleName: "Middle name",
        id: "ID",
        novaPostDeliveryAddress: "Nova post address",
        novaPostDeliveryAddressCity: "Nova post city",
        novaPostDeliveryAddressDepartment: "Nova post department",
        ukrPostDeliveryAddress: "Ukrpost address",
    },
    modal: {
        headers: {
            create: "Create additional contact",
            update: "Update additional contact",
        },
        inputs: {
            firstName: "First name *",
            lastName: "Last name *",
            middleName: "Middle name *",
            position: "Position",
            email: "Email",
            phoneNumber: "Phone number",
            city: "City",
            postDepartment: "Post department",
        },
        create: "Create",
        update: "Update",
        errors: {
            noChanges: "For update, please change at least one field",
            required: "Please check all required fields",
            invalid: "Please check the correctness of the entered data",
        },
    },
};

export default additionalContacts;
