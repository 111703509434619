import { AxiosResponse } from "axios";
import { ComputedRef, ref, watchEffect, computed } from "vue";

export type Status = ReturnType<typeof useStatus>;

export const useStatus = <T>(computedValue: ComputedRef<AxiosResponse<T> | undefined>) => {
    const status = ref<"idle" | "loading" | "success" | "error">("idle");
    const error = ref<null | unknown>(null);

    watchEffect(() => {
        if (status.value == "loading" && computedValue.value != undefined) {
            const res = computedValue.value;
            if (String(res.status).startsWith("2")) status.value = "success";
            else {
                status.value = "error";
                error.value = `${res.statusText} / ${JSON.stringify(res.data)}`;
            }
        }
    });

    return {
        startLoading: (dispatch: Promise<any> | undefined) => {
            status.value = "loading";
            error.value = null;
            return dispatch;
        },
        clearStatus: () => {
            status.value = "idle";
        },
        isError: computed(() => status.value == "error"),
        isLoading: computed(() => status.value == "loading"),
        isIdle: computed(() => status.value == "idle"),
        isSuccess: computed(() => status.value == "success"),
        error: computed(() => error.value),
        data: computed(() => computedValue.value?.data),
    };
};
